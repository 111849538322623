import React from 'react';
import './Loader.css'; // Import the CSS file for styling

const ThreeBodyLoader = () => {
  return (
/* From Uiverse.io by Donewenfu */ 
<div className="loader">
  <div className="justify-content-center jimu-primary-loading"></div>
</div>
  );
};

export default ThreeBodyLoader;
